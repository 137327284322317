/* target the active bullet */
span.swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: white;
  opacity: 0.48;
  width: 16px;
  height: 4px;
  border-radius: 100px;
}

/* target all bullets */
span.swiper-pagination-bullet {
  background-color: white;
  opacity: 0.16;
  width: 4px;
  height: 4px;
}
