.animated {
  animation: spinner 4s linear;
  animation-duration: '1s';
  transform-origin: center;
}

@keyframes spinner {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
